import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import LoginPage from './Components/LoginPage';
import UploadPage from './Components/UploadPage';
import RegisterPage from './Components/RegisterPage';
import HorizontalNavbar from './Components/HorizontalNavbar';
import VerticalNavbar from './Components/VerticalNavbar';
import KpiDashboard from './Components/KpiDashboard';
import VariableProvider from './Components/Providers/VariableProvider';

function NavigationBars() {
  const location = useLocation();

  // Check if the current route is either login, register, or upload
  if (location.pathname === '/' || location.pathname === '/login' || location.pathname === '/register') {
    return null; // Do not render navbars
  }

  return (
    <>
      <HorizontalNavbar />
      {location.pathname !== '/upload' && <VerticalNavbar />} // Conditionally render VerticalNavbar
    </>
  );
}

function App() {
  return (
    <VariableProvider>
      <Router>
        <NavigationBars />
        <div className="main-content"> {/* This will wrap the route content */}
          <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/upload" element={<UploadPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/kpi" element={<KpiDashboard />} />
          </Routes>
        </div>
      </Router>
    </VariableProvider>
  );
}

export default App;
