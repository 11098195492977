import React, { useEffect, useState, useContext,useRef } from 'react';
import './css/KpiDashboard.css';
import SelectedOptionsContext from './Providers/SelectedOptionsContext';
import Loader from './Loader'; // Assume you have a Loader component
import * as XLSX from 'xlsx';
import Modal from './Modal'; // Import the Modal component
import JsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';
import  ExcelIcon  from '../images/excel.jpg'; // SVG icon for Excel
import   PdfIcon  from '../images/pdf.png'; // SVG icon for PDF
import VariableSelector from './VariableSelector';
import config from '../config'; // Adjust the import path as necessary


function KpiDashboard() {
  const tableRef = useRef(null);
  const [topN, setTopN] = useState(8); // State to keep track of the selected number

  const [isModalOpen, setIsModalOpen] = useState(false);

    const [tableData, setTableData] = useState([]);
    
    const [isLoading, setIsLoading] = useState(false); // Add a new state for loading indicator
    const [filterOptions, setFilterOptions] = useState([]);
    const [activeFilters, setActiveFilters] = useState([]); // New state for active filters
    const [triggerUpdate, setTriggerUpdate] = useState(false);

    const { selectedOptions } = useContext(SelectedOptionsContext);
    const [selectedFilter, setSelectedFilter] = useState('q7'); // Default to 'categories'
    useEffect(() => {
      fetchData(selectedFilter);
  }, [topN]);
    const fetchData = (filter) => {
      setIsLoading(true); // Start loading
      const queryParams = new URLSearchParams();
        
      
        for (const [key, value] of Object.entries(selectedOptions)) {
          value.forEach(option => queryParams.append(key, option.value));
      }
      queryParams.append('categoryColumn', filter);
      queryParams.append('top_n', topN); // Include top_n in the request
    
      // Fetch data from backend with the selected filter
      fetch(`${config.API_BASE_URL}/kpi/tablemanual?${queryParams.toString()}`)
        .then(response => response.json())
        .then(data => {
          // Handle the response data
          setTableData(data);
          setIsLoading(false);
        })
        .catch(error => {
          // Handle any errors here
          console.error('Error fetching KPI data:', error);
        });
    };
    useEffect(() => {
      setIsLoading(true);
      fetch(`${config.API_BASE_URL}/api/active-filters`) // Adjust the URL to where your Flask app is hosted
        .then(response => response.json())
        .then(data => {
          setActiveFilters(data);
          setIsLoading(false);
        })
        .catch(error => {
          console.error('Error fetching active filters:', error);
          setIsLoading(false);
        });
    }, [triggerUpdate]);
    const toggleModal = () => setIsModalOpen(!isModalOpen);
    const closeModalAndUpdateButtons = () => {
      setIsModalOpen(false);
      // Change state to trigger useEffect
      setTriggerUpdate(prev => !prev);
    };
    const downloadExcel = () => {
      // Generate workbook from table
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.table_to_sheet(tableRef.current);

      // Define some basic styles (limited support)
      const styles = {
          header: { fill: { fgColor: { rgb: "FF0000FF" } } },
          cell: { fill: { fgColor: { rgb: "FFFF00" } } }
      };

      // Apply styles to header
      for(const col of ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P']) {
          const cellRef = `${col}1`; // Assuming row 1 is your header
          if (worksheet[cellRef]) {
              worksheet[cellRef].s = styles.header;
          }
      }

      // Apply styles to some other cells if necessary
      // ...

      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      XLSX.writeFile(workbook, 'KPIDashboard.xlsx');
  };
  const downloadPDF = () => {
    const input = document.getElementById('kpi-table');
    html2canvas(input)
        .then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new JsPDF({
                orientation: 'landscape',
            });
            const imgProps= pdf.getImageProperties(imgData);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
            pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
            pdf.save('KPIDashboard.pdf');
        });
};
    useEffect(() => {
      setIsLoading(true);
      fetch(`${config.API_BASE_URL}/filters`)
          .then(response => response.json())
          .then(data => {
              setFilterOptions(Object.keys(data));  // Assuming the keys are the filter names
              setIsLoading(false);
          })
          .catch(error => {
              console.error('Error fetching filters:', error);
              setIsLoading(false);
          });
  }, []);
    const handleFilterChange = (newFilter) => {
      setSelectedFilter(newFilter);
      // Here you can also call your API endpoint to fetch new data
      // based on the selected filter.
      fetchData(newFilter);
    };
    useEffect(() => {
      setIsLoading(true); // Start loading
        const queryParams = new URLSearchParams();
        for (const [key, value] of Object.entries(selectedOptions)) {
            value.forEach(option => queryParams.append(key, option.value));
        }
        queryParams.append('categoryColumn',selectedFilter)
        fetch(`${config.API_BASE_URL}/kpi/tablemanual?${queryParams.toString()}`)
            .then(response => response.json())
            .then(data => {
                // Assuming the data is an object with brand names as keys
                // const dataArray = Object.entries(data).map(([brand, values]) => {
                //     return { brand, ...values };
                // });

                setTableData(data);
                setIsLoading(false); // Start loading
                // Determine categories
                // const cats = new Set(['Total']); // Start with 'Total'
                // dataArray.forEach(item => {
                //     Object.keys(item).forEach(cat => cats.add(cat));
                // });
                // setCategories([...cats]); // Convert to array, 'Total' first
            })
            .catch(error => console.error('Error fetching filter data:', error));
    }, [selectedOptions]);

    return (
        <div className="kpi-dashboard">
{/* Dropdown for selecting the number of top brands */}
<select className="custom-dropdown" value={topN} onChange={e => setTopN(e.target.value)}>
            {[3, 5, 8, 10, 20].map(n => (
                <option key={n} value={n}>Top {n}</option>
            ))}
        </select>
            <div>
            {activeFilters.map(filter => (
          <button
            key={filter.varname}
            onClick={() => handleFilterChange(filter.varname)}
            className={selectedFilter === filter.varname ? 'active' : ''}
          >
            {filter.text}
          </button>
        ))}
                <button onClick={toggleModal}>+</button>

<Modal isOpen={isModalOpen} onClose={closeModalAndUpdateButtons}>
  <VariableSelector onModalClose={closeModalAndUpdateButtons} />
</Modal>
      </div>
      <div className="download-buttons">
  <button onClick={downloadExcel} title="Download as Excel">
    <img src={ExcelIcon} alt="Download as Excel" />
  </button>
  <button onClick={downloadPDF} title="Download as PDF">
    <img src={PdfIcon} alt="Download as PDF" />
  </button>
</div>
            <h1>KPI Dashboard</h1>
            {isLoading ? <Loader /> : (
             <table ref={tableRef} id="kpi-table">
                <thead>
                    <tr>
                        
                        {tableData && tableData.categories && tableData.categories.map(cat => (
                            <th key={cat} colSpan={2} style={{ backgroundColor: 'blue', color: 'white' }}>{cat}</th>
                        ))}
                    </tr>
                    <tr>
                        {tableData && tableData.categories && tableData.categories.map(cat => (
                            <>
                                <th key={`${cat}-value`} style={{ backgroundColor: 'lightblue' }}>Mkt size (Mil €)</th>
                                <th key={`${cat}-percent`} style={{ backgroundColor: 'lightblue' }}>% Change vs PY</th>
                            </>
                        ))}
                    </tr>
                    <tr>
                        {/* Category Counts */}
                        {tableData.categories && tableData.categories.map(cat => (
                            <>
                                <td key={`${cat}-count2023`} style={{ backgroundColor: 'white' }}>
                                    {tableData.counts[cat]?.['count_jan_2023'] ?? 'N/A'}
                                </td>
                                <td key={`${cat}-count2024`} style={{ backgroundColor: 'white' }}>
                                    {tableData.counts[cat]?.['count_jan_2024'] ?? 'N/A'}
                                </td>
                            </>
                        ))}
                    </tr>
                                        <tr> {/* Row with specific data */}
                        {tableData.categories && tableData.categories.map(cat => (
                            <>
                                <td key={`${cat}-value`} style={{ backgroundColor: 'white' }}>
                                    {/* Placeholder or actual data */}
                                    {'Value Share'}
                                </td>
                                <td key={`${cat}-bps`} style={{ backgroundColor: 'white' }}>
                                    {/* Placeholder or actual data */}
                                    {'Δ Bpts vs PY'}
                                </td>
                            </>
                        ))}
                    </tr>
                </thead>
                <tbody>
  {Array.from({ length: topN }, (_, i) => i + 1).map(rankIndex => (
    <>
      <tr>
        {tableData && tableData.categories && tableData.categories.map(category => {
          const brandData = tableData.Top8brands[category] ? tableData.Top8brands[category][rankIndex - 1] : null;
          const isNivea = brandData && brandData.brand === 'NIVEA';
          const brandStyle = {
            backgroundColor: isNivea ? 'blue' : 'grey',
            color: isNivea ? 'white' : 'black',
          };
          return (
            <th key={`${category}-brand-${rankIndex}`} colSpan={2} style={brandStyle}>
              {brandData ? brandData.brand : 'N/A'}
            </th>
          );
        })}
      </tr>
      <tr>
      {tableData && tableData.categories && tableData.categories.map(category => {
  const brandData = tableData.Top8brands[category] ? tableData.Top8brands[category][rankIndex - 1] : null;
  return (
    <>
      <td key={`${category}-2024-${rankIndex}`} style={{ backgroundColor: 'white'}}>
        {brandData?.value_2024 ?? 'N/A'}
      </td>
      <td key={`${category}-2023-${rankIndex}`} style={{backgroundColor: 'white', color: (brandData?.value_2024 - brandData?.value_2023 ?? 0) > 0 ? 'green' : 'red'}}>
        {brandData?.value_2023 ?? 'N/A'} ({(brandData?.value_2024 - brandData?.value_2023 ?? 0).toFixed(2)})
      </td>
    </>
  );
})}
      </tr>
    </>
  ))}
</tbody>

            </table>
            
            )}
        </div>
    );
}

export default KpiDashboard;
