import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './css/LoginPage.css'; // Make sure the path matches your CSS file location
import config from '../config'; // Adjust the import path as necessary

function LoginPage() {
    const [user, setUser] = useState({ username: '', password: '' });
    const navigate = useNavigate();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUser(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${config.API_BASE_URL}/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(user),
            });
            const data = await response.json();
            if (response.ok) {
                navigate('/kpi'); // Redirect to upload page
            } else {
                alert(data.message); // Show message from the server
            }
        } catch (error) {
            console.error('Login error:', error);
            alert('Login failed. Please try again later.');
        }
    };

    return (
        <div className="login-container">
            <form onSubmit={handleSubmit} className="login-form">
                <h2>Login</h2>
                <div className="input-group">
                    <label htmlFor="username">Username (Email)</label>
                    <input
                        type="email"
                        id="username"
                        name="username"
                        value={user.username}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="input-group">
                    <label htmlFor="password">Password</label>
                    <input
                        type="password"
                        id="password"
                        name="password"
                        value={user.password}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <button type="submit" className="login-button">Log In</button>
                <button type="button" className="register-button" onClick={() => navigate('/register')}>Register</button>
            </form>
        </div>
    );
}

export default LoginPage;
