import React, { useEffect, useState, useContext } from 'react';
import Select from 'react-select';
import './css/VerticalNavbar.css';
import SelectedOptionsContext from './Providers/SelectedOptionsContext';
import config from '../config'; // Adjust the import path as necessary

function VerticalNavbar() {
    const [filters, setFilters] = useState({});
    const { selectedOptions, setSelectedOptions  } = useContext(SelectedOptionsContext);

    // Define label translations
    const filterLabels = {
      Q3: "Country",
      Q5: "Brands",
      Q7: "Categories",
      Q11: "Gender",
      Q14: "Variants"
    };

useEffect(() => {
        fetch(`${config.API_BASE_URL}/filters`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok.');
                }
                return response.json();
            })
            .then(data => {
                const transformedData = Object.entries(data).reduce((acc, [key, values]) => {
                    if (values.length > 0) {
                        acc[key] = values.map(value => ({ label: value, value }));
                    }
                    return acc;
                }, {});
                setFilters(transformedData);
            })
            .catch(error => console.error('Error fetching filter data:', error));
    }, []);


    const handleSelectChange = (selected, { name }) => {
        setSelectedOptions(prev => ({
            ...prev,
            [name]: selected
        }));
    };

    return (
        <div className="vertical-navbar">
            
            {Object.entries(filters).map(([column, options]) => (
                <div key={column}>
                    <label>{filterLabels[column] || column}</label>
                    <Select
                        isMulti
                        name={column}
                        options={options}
                        value={selectedOptions[column]}
                        onChange={handleSelectChange}
                        className="filter-dropdown"
                        classNamePrefix="select"
                    />
                </div>
            ))}
            {Object.keys(filters).length === 0 && <p>No filters available.</p>}
        </div>
    );
}

export default VerticalNavbar;
