import React, { useEffect, useState } from 'react';
import './css/VariableSelector.css'; // Make sure to import your CSS file
import config from '../config'; // Adjust the import path as necessary

function VariableSelector({ onModalClose }) {
  const [variables, setVariables] = useState([]);

  // Fetch variables when the component mounts
  useEffect(() => {
    fetch(`${config.API_BASE_URL}/api/visible-variables`) // Adjust with your Flask API endpoint
      .then(response => response.json())
      .then(data => setVariables(data))
      .catch(error => console.error('Error fetching variables:', error));
  }, []);

  // Function to handle toggle change
  const handleToggle = (varname, currentValue) => {
    // Update the local state
    if (varname === 'Q7') {
      return;
    }
    const updatedVariables = variables.map(v =>
      v.varname === varname ? { ...v, active: !currentValue } : v
    );
    setVariables(updatedVariables);

    // Send the update to the backend
    fetch(`${config.API_BASE_URL}/api/update-variable`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ varname: varname, active: !currentValue }),
    })
    .then(response => response.json())
    .then(data => console.log(data.message))
    .catch(error => console.error('Error updating variable:', error));
    
  };
  const ToggleButton = ({ isChecked, onToggle }) => {
    return (
      <div className={`toggle-button ${isChecked ? 'on' : 'off'}`} onClick={onToggle}>
        <div className="toggle-knob"></div>
      </div>
    );
  };
  
  return (
    <div>
      <h2>Select a Variable</h2>
      <div className="variable-grid">
        {variables.map((variable) => (
          <div key={variable.varname} className="variable-row">
            <span>{variable.text}</span>
            <ToggleButton
              isChecked={variable.active}
              onToggle={() => handleToggle(variable.varname, variable.active)}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default VariableSelector;
