import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
//import './css/RegisterPage.css'; // Ensure you have styling for the registration page
import config from '../config'; // Adjust the import path as necessary

function RegisterPage() {
    const navigate = useNavigate();
    const [newUser, setNewUser] = useState({ name: '', email: '', password: '' });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewUser(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${config.API_BASE_URL}/register`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(newUser),
            });
    
            // Check if the response was successful based on the status code
            if (response.ok) {
                alert('Registered successfully!');
                navigate('/login'); // Redirect to login or another appropriate page
            } else {
                // Handle HTTP error responses
                const error = await response.json();
                alert(`Registration failed: ${error.message}`);
            }
        } catch (error) {
            // Handle network errors or other unexpected errors
            console.error('Registration error:', error);
            alert('Failed to register. Please try again later.');
        }
    };
    

    return (
        <div className="register-container">
            <form onSubmit={handleSubmit} className="register-form">
                <h2>Register</h2>
                <div className="input-group">
                    <label htmlFor="name">Name</label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={newUser.name}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="input-group">
                    <label htmlFor="email">Email</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={newUser.email}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="input-group">
                    <label htmlFor="password">Password</label>
                    <input
                        type="password"
                        id="password"
                        name="password"
                        value={newUser.password}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <button type="submit" className="register-button">Register</button>
            </form>

        </div>
    );
}

export default RegisterPage;
