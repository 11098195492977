// HorizontalNavbar.js

import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './css/HorizontalNavbar.css';
import logo from '../images/Beiersdorf-logo-2021.png'; // Adjust the path to where your logo is stored
import '@fortawesome/fontawesome-free/css/all.min.css';
import config from '../config'; // Adjust the import path as necessary

function HorizontalNavbar() {
    const navigate = useNavigate();
    const handleLogout = () => {
        // Insert your logout logic here
        navigate('/login');
    };

    return (
        <nav className="horizontal-navbar">
            <Link to="/" className="navbar-logo-link">
                <img src={logo} alt="Logo" className="navbar-logo" />
            </Link>
            <div className="navbar-links">
                <Link to="/kpi">KPI Dashboard</Link>
                <Link to="/upload">Upload Data</Link>
            </div>
            <div className="logout-icon" onClick={handleLogout}>
                {/* Replace this with your actual logout icon */}
                <i className="fas fa-sign-out-alt"></i>
            </div>
        </nav>
    );
}

export default HorizontalNavbar;
