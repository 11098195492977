// VariableProvider.js
import React, { useState } from 'react';
import SelectedOptionsContext from './SelectedOptionsContext';

const VariableProvider = ({ children }) => {
    const [selectedOptions, setSelectedOptions] = useState({});

    return (
        <SelectedOptionsContext.Provider value={{ selectedOptions, setSelectedOptions }}>
      {children}
        </SelectedOptionsContext.Provider>
    );
}

export default VariableProvider;