import React, { useState } from 'react';
import './css/UploadPage.css'; // For styling, create a corresponding CSS file
import config from '../config'; // Adjust the import path as necessary

function UploadPage() {
    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleUpload = async () => {
        if (!selectedFile) {
            alert('Please select a file first!');
            return;
        }

        const formData = new FormData();
        formData.append('file', selectedFile);

        try {
            const response = await fetch(`${config.API_BASE_URL}/upload`, {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                const result = await response.json();
                alert(result.success);
                // Handle success, maybe clear the selected file or redirect
            } else {
                // Handle server errors or invalid responses
                alert('Failed to upload file. Please try again.');
            }
        } catch (error) {
            console.error('Error uploading file:', error);
            alert('Error uploading file. Please check your connection and try again.');
        }
    };

    return (
        <div className="upload-container">
            <h2>Upload Excel File</h2>
            <input type="file" accept=".xlsx, .xls" onChange={handleFileChange} />
            <button onClick={handleUpload}>Upload File</button>
        </div>
    );
}

export default UploadPage;
